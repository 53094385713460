import React, { Component } from "react";

import ServiceSettings from "../services/serviceSettings"

interface ErrorHandlerState {
    hasError: boolean,
}

interface ErrorHandlerProps {
    errorMessage: string
}

export class ErrorHandler extends Component<ErrorHandlerProps,
  ErrorHandlerState> {
    constructor(props: ErrorHandlerProps) {
      super(props);
      this.state = {
        hasError: !!this.props.errorMessage
      }
    }

    componentDidCatch(error: any, info: any) {
      this.setState({ hasError: true });
    }

    render() {
      if (!!this.props.errorMessage) {
        return <h1>{this.props.errorMessage}</h1>
      }
      else if (this.state.hasError) {
      return (
      <h1>
        Ops... Something went wrong.
        Please contact {ServiceSettings.contactEmail}.
      </h1>);
      }
      return this.props.children;
    }
  }