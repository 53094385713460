import { ReactElement } from "react";
import React from "react";

export class Loader extends React.Component {
    render(): ReactElement {
        return (
            <div className="loader-overlay">
                <div className="lds-grid">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}