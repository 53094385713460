import React from 'react';

export default function Instructions() {
    return (
<div className="instructions-container">
    <h4>Do you need Azure DevOps access?</h4>
    <ul>
        <li>
            If you have a proper&nbsp;
            <a href="https://docs.microsoft.com/en-us/azure/devops/organizations/security/access-levels?view=azure-devops#supported-access-levels">
                                license
                            </a>, please contact project
            administrators which you can find on the left.
        </li>
        <li>
            If you do not have the proper Azure DevOps license:
            <ol>
                <li>
                    Submit a ticket in&nbsp;
                    <a href="http://servicedesk.demant.com/">Service desk</a>
                    &nbsp;in the "Azure DevOps" category providing:
                    <ul>
                        <li>
                            Name of the organisation.
                        </li>
                        <li>
                            <a href="https://docs.microsoft.com/en-us/azure/devops/organizations/security/access-levels?view=azure-devops#supported-access-levels">
                                License type.
                            </a>
                        </li>
                        <li>
                            <strong>Attach your manager's approval.</strong>
                        </li>
                    </ul>
                </li>
                <li>
                    After you get the license please contact a project
                    administrator which you can find on the left.
                </li>
            </ol>
        </li>
        <li>
            If you can not find your project on the list, please submit
            a new <a href="http://servicedesk.demant.com/">Service desk</a>
            &nbsp;ticket in the "Azure DevOps" category providing:
            <ul>
                <li>
                    Name of the organisation.
                </li>
                <li>
                    Name of the project.
                </li>
                <li>
                    <strong>Attach your manager's approval.</strong>
                </li>
            </ul>
        </li>
        <li>
            In case of any doubts, please email&nbsp;
            <a href="mailto: cloudheroes@demant.com">
                cloudheroes@demant.com
            </a>.
        </li>
    </ul>
</div>
    );
}
