/**
 * Reads application settings from metadata elements inside index.html.
 * This way settings can be conveniently modified and
 * configured by environment in automation pipelines, even after the code is
 * built by webpack tasks.
 */
function readFromMetadataElement(settingId: string): string {
    const value = readFromMetadataElementOrEmpty(settingId);
    if (!value) {
      throw new Error(
        `Invalid "${settingId}" metadata element: missing value.`
        );
    }

    return value;
  }

  function readFromMetadataElementOrEmpty(settingId: string): string {
    const element = document.getElementById(settingId);
    if (element === null) {
      throw new Error(`Missing "${settingId}" metadata element.`);
    }
    const value = element.getAttribute("content");
    if (!value) {
      return "";
    }

    return value;
  }


  class ServiceSettings {
    private _apiURL: string
    private _apiScope: string
    private _clientId: string
    private _authority: string
    private _redirectUrl: string
    private _contactEmail: string
    private _instrumentationKey: string

    public get apiURL(): string {
      return this._apiURL;
    }

    public get apiScope(): string {
      return this._apiScope;
    }

    public get clientId(): string {
      return this._clientId;
    }

    public get authority(): string {
      return this._authority;
    }

    public get redirectUrl(): string {
      return this._redirectUrl;
    }

    public get contactEmail(): string {
        return this._contactEmail;
      }

    public get instrumentationKey(): string {
        return this._instrumentationKey;
      }


    constructor() {
      this._apiURL = readFromMetadataElement("api-url");
      this._apiScope = readFromMetadataElement("api-scope");
      this._clientId = readFromMetadataElement("client-id");
      this._authority = readFromMetadataElement("authority");
      this._redirectUrl = readFromMetadataElement("redirect-url");
      this._contactEmail = readFromMetadataElement("contact-email");
      this._instrumentationKey = readFromMetadataElementOrEmpty(
        "instrumentation-key");
    }
  }


  export default new ServiceSettings();
