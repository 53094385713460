import React, { ReactElement } from "react";

export class AppHeader extends React.Component {
    render(): ReactElement {
        return (
        <header>
            <div className="header-child"></div>
            <div>Demant</div>
            <div className="header-child">
                <mgt-login></mgt-login>
            </div>
        </header>
        );
    }
}