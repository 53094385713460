import { Providers, MsalProvider, ProviderState } from "@microsoft/mgt"

import ServiceSettings from "../services/serviceSettings"
import { appMsalConfig } from "./config";

export class AuthService {
    msal: MsalProvider;

    constructor() {
        this.msal = Providers.globalProvider as MsalProvider
    }

    async getAccessToken(): Promise<string> {
        let token = ""
        let counter = 0;
        while (true) {
            try {
                counter++;
                token = await this.msal
                    .getAccessTokenForScopes(ServiceSettings.apiScope);
                return token;
            } catch (error) {
                if (counter > 3) {
                    throw error;
                }
            }
        }
    }

    /**
     * Returns true when the method will redirect to the log-in page
     * @param organizationName
     */
    async forceSignInIfNotAuthenticated(organizationName: string): Promise<boolean> {
        if (this.isSignedIn()) {
            return false;
        }
        else if (this.isSignedOut()) {
            let redirectUrl = `${ServiceSettings.redirectUrl}/${organizationName}`;
            await this.msal.login({
                scopes: appMsalConfig.scopes,
                authority: appMsalConfig.authority,
                redirectUri: redirectUrl
            });
            return true;
        } else {
          await new Promise(resolve => {
            setTimeout(async () => {
                let result = await this.forceSignInIfNotAuthenticated(organizationName);
                resolve(result);
              }, 50);
          })
        }
        return false;
      }

    private isSignedOut(): boolean {
        return this.msal.state === ProviderState.SignedOut
    }

    private isSignedIn(): boolean {
        return this.msal.state === ProviderState.SignedIn
    }
}