import { Organization } from "../models/organization";
import ServiceSettings from "./serviceSettings"
import * as constants from "../constants"

export class OrganizationService {
    async getOrganization(organizationName: string, accessToken: string):
        Promise<Organization> {
        let url = `${ServiceSettings.apiURL}/api/` +
            `organizations/${organizationName}`;
        let organization = await this.get<Organization>(url, accessToken);
        return organization;
    }

    async get<T>(url: string, token: string): Promise<T> {
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`)
        let response = await fetch(url, {
            headers: headers,
            method: "GET"
        })

        if (response.ok) {
            try {
                return await response.json();
            } catch (error) {
                return null!;
            }
        } else
            throw new Error(`Status code does not indicate success:
                ${response.status}`);
        }

    getCurrentOrganizationName(): string | null {
        let pathArray = window.location.pathname.split('/');
        let arrayLength = pathArray.length;
        if (arrayLength !== 2) {
            return null;
        }
        let name = pathArray[arrayLength - 1];
        if (!this.isKnownOrganization(name)) {
            return null
        }
        return name;
    }

    private isKnownOrganization(name: string) {
        return constants.KNOWN_ORGANIZATIONS
            .map(o => o.toLowerCase())
            .indexOf(name) > -1;
    }
}