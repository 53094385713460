import "@webcomponents/custom-elements";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Providers, MsalProvider } from "@microsoft/mgt";

import "./index.css";
import App from "./App";
import { appMsalConfig } from "./auth/config"
import { UserAgentService} from "./services/userAgentService";
import ServiceSettings from "./services/serviceSettings";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "mgt-login": any;
      "mgt-msal-provider": any,
      "mgt-person": any,
      "mgt-people": any
    }
  }
}

let userAgentService = new UserAgentService();
if (userAgentService.isUserAgentSupported()) {
  configureApplicationInsights();
  configureMsalProvider();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

  serviceWorker.unregister();
} else {
  displayNotSupportedBrowserText();
}

function configureMsalProvider() {
  Providers.globalProvider = new MsalProvider(appMsalConfig);
}

function displayNotSupportedBrowserText() {
  let element = document.getElementById("root");
  let text = document.createTextNode(
    "Your browser is not supported. Please run the page in a modern browser."
  );
  element!.appendChild(text);
}

function configureApplicationInsights() {
  if (!!ServiceSettings.instrumentationKey) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: ServiceSettings.instrumentationKey
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
}

