import React, { ReactElement, Component } from "react";
import { Grid } from "@material-ui/core";

import { AppHeader } from "./app-header"
import Instructions from "./instructions"
import { ProjectsCatalog } from "./projects-catalog"
import { Organization } from "../models/organization";

interface LayoutProps {
    organization: Organization
}

export class Layout extends Component<LayoutProps, unknown> {
    render(): ReactElement {
        return (
            <React.Fragment>
                <AppHeader></AppHeader>
                <div className="body-container">
                <Grid container spacing={10} wrap="wrap-reverse">
                    <Grid item xs={12} sm={6}>
                        <ProjectsCatalog
                            organization={this.props.organization}>
                        </ProjectsCatalog>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Instructions></Instructions>
                    </Grid>
                </Grid>
                </div>
            </React.Fragment>
        );
    }
}