import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Component, ReactElement } from "react";
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import { Organization } from "../models/organization";
import { InputAdornment, Grid } from '@material-ui/core';

interface ProjectsCatalogState {
    textFilter: string
}

interface ProjectsCatalogProps {
    organization: Organization
}

export class ProjectsCatalog extends Component<ProjectsCatalogProps,
    ProjectsCatalogState> {
    constructor(props: ProjectsCatalogProps) {
        super(props);

        this.state = this.initialState();
    }

    initialState(): ProjectsCatalogState {
        return {
            textFilter: ""
        }
    }

    onFilterChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            textFilter: event.target.value
        });
    }

    render(): ReactElement {
        const organization = this.props.organization;
        const { textFilter }  = this.state

        let filteredProjects = organization.Projects
            .filter(p => p.Users.length > 0);
        if (!!textFilter) {
            filteredProjects = filteredProjects
                .filter(project => project.DisplayName.toLowerCase()
                .indexOf(textFilter.toLowerCase()) > -1);
        }

        let projects = filteredProjects.map(project =>
            <TreeItem
                nodeId={Math.random().toString()}
                label={project.DisplayName}
                key={Math.random().toString()}>
                {project.Users.map(u =>
                    <mgt-person
                        user-id={u.ObjectId}
                        show-presence={true}
                        view="twoLines"
                        person-card="hover"
                        key={Math.random().toString()}>
                    </mgt-person>
                )}
            </TreeItem>
        );
        let noProjectsFound = projects.length === 0;

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            onChange={this.onFilterChange.bind(this)}
                            placeholder="Project name"
                            autoFocus={true}
                            className="searchButton"
                            fullWidth={true}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                <br/>
                <div>
                    <strong>
                        Project administrators:
                    </strong>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        >
                        {projects}
                    </TreeView>
                </div>
                {
                    noProjectsFound &&
                    <div>
                        Can not find your project? Please follow the
                        instructions on the right side.
                    </div>
                }
            </React.Fragment>
        );

    }
}