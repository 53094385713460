import "./styles/global.scss";

import React, { ReactElement, Component } from "react";
import "@microsoft/mgt";
import { ThemeProvider } from "@material-ui/core";

import { AppError } from "./errors/appError"
import { AuthService } from "./auth/authService"
import { ErrorHandler } from "./components/error-handler"
import { Layout } from "./components/layout"
import { Loader } from "./components/loader"
import { Organization } from "./models/organization";
import { OrganizationNotFoundError } from "./errors/organizationNotFoundError"
import { OrganizationService } from "./services/organizationService"
import theme from "./components/theme"
import * as constants from "./constants";


interface AppState {
  isAuthenticated: boolean,
  error: string,
  loading: boolean
  organization: Organization | null,
  organizationName: string,
  organizationService: OrganizationService,
  authService: AuthService
}

export default class App extends Component<unknown, AppState> {
  constructor(props: unknown) {
    super(props);
    this.state = this.getInitState();
  }

  async componentDidMount(): Promise<void> {
    try {
      let authService = this.state.authService;
      await this.setupOrganizationContext();
      let organizationName = this.state.organizationName;
      let willRedirect = await authService
        .forceSignInIfNotAuthenticated(organizationName);
      if (!willRedirect) {
        await this.loadOrganization();
      }
    } catch (error) {
      if (error instanceof AppError && error.friendly) {
        await this.onError(error.text)
      } else {
        await this.onError("Ops... something went wrong...")
      }
    }
  }

  getInitState(): AppState {
    return {
      isAuthenticated: false,
      error: "",
      loading: true,
      organization: null,
      organizationName: "",
      organizationService: new OrganizationService(),
      authService: new AuthService()
    }
  }

  setupOrganizationContext(): Promise<void> {
    return new Promise((resolve => {
      let orgarnizationService = this.state.organizationService;
      let name = orgarnizationService.getCurrentOrganizationName();
      if (!name) {
        let newPath = `${window.location.origin}/` +
          `${constants.DEFAULT_ORGANIZATION}`;
        window.location.href = newPath;
      } else {
        this.setState({
          organizationName: name,
        }, resolve);
      }
    }))
  }

  async loadOrganization(): Promise<void> {
    let { organizationService, organizationName, organization } = this.state;
    let { authService } = this.state;
    if (!organization) {
      let token = await authService
        .getAccessToken();
      let organization = await organizationService
        .getOrganization(organizationName, token);
      if (organization) {
        await this.onLoadOrganizationSuccess(organization);
      } else {
        throw new OrganizationNotFoundError();
      }
    }
  }

  onLoadOrganizationSuccess(orgzanization: Organization): Promise<void>{
    return new Promise(resolve => {
      this.setState({
        isAuthenticated: true,
        loading: false,
        organization: orgzanization
      }, resolve)
    })
  }

  onError(error: string): Promise<void>{
    return new Promise(resolve => {
      this.setState({
        error: error,
        loading: false
      }, resolve)
    })
  }

  render(): ReactElement {
    const { loading, error, organization } = this.state;
    return (
      <ErrorHandler errorMessage={error}>
        <ThemeProvider theme={theme}>
              {loading && <Loader></Loader>}
              {!loading && <Layout organization={organization!}></Layout>}
          </ThemeProvider>
      </ErrorHandler>
      );
    }
 }